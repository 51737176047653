.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
    z-index: 9999;
}

.modal-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  height: auto;
  max-width: 400px;
  margin: 40px auto;
  z-index: 9999;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.modal-header h2 {
  margin: 0;
}

.modal-header button {
  background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.modal-body {
  padding: 20px;
}

.modal-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-body li {
  margin-bottom: 10px;
}

.modal-body a {
  text-decoration: none;
  color: #6417E0;
}

.modal-body a:hover {
  color: #5d1fc1;
}