/* Reset and base styles  */
* {
	padding: 0px;
	margin: 0px;
	border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
  transition: all;
  transition: 0.25s;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Links */

a, a:link, a:visited  {
  text-decoration: none;
}

a:hover  {
  text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
	display: block;
}

p {
	font-weight: inherit;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
}

ul, ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img, svg {
	max-width: 100%;
	height: auto;
}

address {
  font-style: normal;
}

/* Form */

input, textarea, button, select {
	font-family: inherit;
  font-size: inherit;
  color: inherit;
}

input::-ms-clear {
	display: none;
}

button, input[type="submit"] {
	display: inline-block;
	box-shadow: none;
	background-color: transparent;
	background: none;
	cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}