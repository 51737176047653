/* Imports */
@font-face {
  font-family: Regular;
  src: url(../Fonts/Manrope-Regular.ttf);
}
@font-face {
  font-family: Bold;
  src: url(../Fonts/Manrope-Bold.ttf);
}

/* Root */
:root {
  --background--color: #ffffff;
  --second--background--color: #e1e1e15d;
  --light--text--color: #ffffff;
  --dark--text--color: #1d1d1d;
  --accent--color: #6417E0;
  --hover--color: #4b1b97;
}

/* Tags */
a {
  color: var(--dark--text--color);
}
a:hover {
  color: var(--accent--color);
}

/* Debug */
/* * {
  .Debug--Mode * {
    outline: 1px solid #ff0000;
  }
} */

/* Styles */
body {
  background-color: var(--background--color);
  color: var(--dark--text--color);
  font-family: Regular;
  width: 100%;
  height: 100%;
}

/* Page */
.Page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
}

/* Header */
.Header--Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 100%;
}

.Header--Container--Item {
  display: flex;
  align-items: center;
}

.Header--Container--Item img {
  max-width: 100%;
  height: auto;
}

.Header--Container--Item--NavBar {
  display: flex;
  gap: 20px;
  align-items: center;
}

@media (min-width: 768px) {
  .Header--Container--Item img {
    width: 120px;
  }
}

@media (min-width: 480px) {
  .Header--Container--Item img {
    width: 150px;
  }
}

/* Footer */
.Footer--Container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #000;
  color: var(--light--text--color);
  width: 100%;
  padding: 16px;
  gap: 20px;
}
.Footer--Container a {
  color: var(--light--text--color);
}
.Footer--Container--Item {
  display: flex;
  justify-content: space-between;
}

/* Block */
.Block--Container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Block--Container--Item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--second--background--color);
  padding: 16px;
  border-radius: 16px;
}
.Block--Item--Header {
  display: flex;
  gap: 20px;
  align-items: center;
}
/* .Block--Item--Content {

} */
 .Block--Header--Image {
  display: flex;
  align-items: center;
  background-color: var(--accent--color);
  color: var(--light--text--color);
  padding: 4px;
  border-radius: 6px;
 }